import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header";
import SideBarAdmin from "../layouts/SideBarAdmin";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import caminho from "../../../services/Api";

const ContactoIndex = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [contactos, setItems] = useState([]);
    const { id } = useParams(); // Obter o ID do serviço da URL

    //    Função para excluir um item
    const deleteItem = async (id) => {
        try {
            await axios.delete(`${caminho.deletecontactosevidorapi}/${id}`);
            setItems(contactos.filter((prof) => prof.id !== id));

            Swal.fire({
                title: "Sucesso!",
                text: "Dado Excluido com sucesso!",
                icon: "success",
                confirmButtonText: "Sim",
            });
        } catch (error) {
            console.error("Erro ao excluir o item:", error);
        }
    };

    useEffect(() => {
        // Fazer a requisição para a API do Laravel
        axios
            .get(caminho.contactosevidorapi)
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>

            <div id="wrapper">
                <SideBarAdmin />

                <div id="main-content">
                    <div class="container-fluid">
                        <div class="block-header">
                            <div class="row  clearfix d-fex justify-content-center">
                                <div class="col-lg-10 ">
                                    <h2 style={{ float: "left" }}>
                                        {/* <i class="fa fa-arrow-left"></i> */}
                                        Listagem de Contacto
                                    </h2>

                                    <Link
                                        to="/admin/contactocreate"
                                        style={{ float: "right" }}
                                    >
                                        <button className="btn btn-primary cor_padrao">
                                            Registar
                                        </button>
                                    </Link>
                                </div>
                                {/* <div class="col-lg-10  text-right"></div> */}
                            </div>
                        </div>

                        <div class="row clearfix d-fex justify-content-center">
                            <div class="col-lg-10">
                                <div class="card">
                                    <div class="header">
                                        {/* <h2>Basic Example 2</h2> */}
                                    </div>
                                    <div class="body">
                                        <div class="table-responsive">
                                            <table class="table table-hover m-b-0 c_list">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nome</th>
                                                        <th>Email</th>
                                                        <th>Numero</th>
                                                        <th>Assunto</th>
                                                        <th>Status</th>
                                                        <th>Acções</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contactos.map(
                                                        (contacto) => (
                                                            <tr>
                                                                <td>#</td>

                                                                <td>
                                                                    {" "}
                                                                    {
                                                                        contacto.nome
                                                                    }{" "}
                                                                </td>

                                                                <td>
                                                                    {" "}
                                                                    {
                                                                        contacto.imail
                                                                    }{" "}
                                                                </td>

                                                                <td>
                                                                    {" "}
                                                                    {
                                                                        contacto.numero
                                                                    }{" "}
                                                                </td>

                                                                <td>
                                                                    {" "}
                                                                    {
                                                                        contacto.assunto
                                                                    }{" "}
                                                                </td>

                                                                <td>
                                                                    <>
                                                                        {contacto.status ===
                                                                        "ativado" ? (
                                                                            <span
                                                                                className="btn-primary p-1"
                                                                                style={{
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    contacto.status
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="btn-danger p-1"
                                                                                style={{
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    contacto.status
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                </td>

                                                                <td>
                                                                    <button
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/admin/contactoedit/${contacto.id}`
                                                                            );
                                                                            console.log(
                                                                                "clicked"
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        class=" btn-info"
                                                                        title="Edit"
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        data-type="confirm"
                                                                        class=" btn-danger js-sweetalert ml-1"
                                                                        title="Delete"
                                                                        onClick={() =>
                                                                            deleteItem(
                                                                                contacto.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <i class="far fa-trash-alt"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactoIndex;
