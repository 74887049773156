import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header";
import SideBarAdmin from "../layouts/SideBarAdmin";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import caminho from "../../../services/Api";

const GaleriaIndex = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [galerias, setItems] = useState([]);
    const { id } = useParams(); // Obter o ID do serviço da URL

    //    Função para excluir um item
    const deleteItem = async (id) => {
        try {
            await axios.delete(`${caminho.deletegaleriasevidorapi}/${id}`);
            setItems(galerias.filter((prof) => prof.id !== id));

            Swal.fire({
                title: "Sucesso!",
                text: "Dado Excluido com sucesso!",
                icon: "success",
                confirmButtonText: "Sim",
            });
        } catch (error) {
            console.error("Erro ao excluir o item:", error);
        }
    };

    useEffect(() => {
        // Fazer a requisição para a API do Laravel
        axios
            .get(caminho.galeriasevidorapi)
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>

            <div id="wrapper">
                <SideBarAdmin />

                <div id="main-content">
                    <div class="container-fluid">
                        <div class="block-header">
                            <div class="row  clearfix d-fex justify-content-center">
                                <div class="col-lg-10 ">
                                    <h2 style={{ float: "left" }}>
                                        {/* <i class="fa fa-arrow-left"></i> */}
                                        Galerias
                                    </h2>

                                    <Link
                                        to="/admin/galeriacreate"
                                        style={{ float: "right" }}
                                    >
                                        <button className="btn btn-primary cor_padrao">
                                            Registar
                                        </button>
                                    </Link>
                                </div>
                                {/* <div class="col-lg-10  text-right"></div> */}
                            </div>
                        </div>

                        <div class="row clearfix d-fex justify-content-center">
                            <div class="col-lg-10">
                                <div class="card">
                                    <div class="header">
                                        {/* <h2>Basic Example 2</h2> */}
                                    </div>
                                    <div class="body">
                                        <div class="table-responsive">
                                            <table class="table table-hover m-b-0 c_list">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>imagem</th>
                                                        <th>Status</th>
                                                        <th>Acções</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {galerias.map((galeria) => (
                                                        <tr>
                                                            <td>#</td>

                                                            <td>
                                                                <img
                                                                    src={`${caminho.$meustorage}/${galeria.imagem}`}
                                                                    class="rounded-circle avatar"
                                                                    alt=""
                                                                />
                                                            </td>

                                                            <td>
                                                                <>
                                                                    {galeria.status ===
                                                                    "ativado" ? (
                                                                        <span
                                                                            className="btn-primary p-1"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                galeria.status
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="btn-danger p-1"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                galeria.status
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </>
                                                            </td>

                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/admin/galeriaedit/${galeria.id}`
                                                                        );
                                                                        console.log(
                                                                            "clicked"
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                    class=" btn-info"
                                                                    title="Edit"
                                                                >
                                                                    <i class="fa fa-edit"></i>
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    data-type="confirm"
                                                                    class=" btn-danger js-sweetalert ml-1"
                                                                    title="Delete"
                                                                    onClick={() =>
                                                                        deleteItem(
                                                                            galeria.id
                                                                        )
                                                                    }
                                                                >
                                                                    <i class="far fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default GaleriaIndex;
