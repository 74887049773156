import React, { useEffect } from "react";
// Import Swiper React components
const Horario = () => {
    //   var settings = {
    //     dots: true,
    //     fade: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     waitForAnimate: false,
    //     autoplaySpeed: 3000,
    //     autoplay: true,
    //   };

    return (
        <div>
            <style>
                {`
          td {
            backgroundColor:#999;
          }
        `}
            </style>

            <div id="breadcrumb" class="division">
                <div class="container">
                    <div class="row" style={{ display: "block" }}>
                        <div class="col">
                            <div class=" breadcrumb-holder">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            <a href="demo-1.html">Início</a>
                                        </li>
                                        <li
                                            class="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            {" "}
                                            Horário de funcionamento
                                        </li>
                                    </ol>
                                </nav>

                                <h4 class="h4-sm steelblue-color">
                                    {" "}
                                    Horário de funcionamento{" "}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                id="info-9"
                class=" info-section division"
                style={{ backgroundColor: "#999" }}
            >
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12">
                            <div
                                class="info-9-table white-color wow fadeInUp"
                                data-wow-delay="0.4s"
                                // style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.4s",
                                    animationName: "fadeInUp",
                                }}
                            >
                                <p>
                                    A Clínica CLISPAJ funciona de segunda a
                                    sábado. Durante esse período, oferecemos uma
                                    variedade de serviços médicos e de saúde
                                    para atender às necessidades dos nossos
                                    pacientes.
                                </p>

                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                Emergência
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                {" "}
                                                -{" "}
                                            </td>
                                            <td
                                                class="text-right"
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                {" "}
                                                24 / 24 Horas ao dia
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                Consulta - Segunda-Sexta
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                {" "}
                                                -{" "}
                                            </td>
                                            <td
                                                class="text-right"
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                8H00 - 18H00
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                Unidade - Segunda-Sexta
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                {" "}
                                                -{" "}
                                            </td>
                                            <td
                                                class="text-right"
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                {" "}
                                                07H00 - 19H30{" "}
                                            </td>
                                        </tr>

                                        <tr class="last-tr">
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                Sábado
                                            </td>
                                            <td
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                -
                                            </td>
                                            <td
                                                class="text-right"
                                                style={{
                                                    backgroundColor: "#999",
                                                }}
                                            >
                                                07H00 - 12H00
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Horario;
