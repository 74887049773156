import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Carousel } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import caminho from "../services/Api";

// Import Swiper styles
import "swiper/css";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import { left } from "@popperjs/core";

const Home = (props) => {
    const navigate = useNavigate();
    const [banners, setItems] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [profissionals, setProfissionals] = useState([]);
    const [show, setShow] = useState(false);
    const { id } = useParams(); // Obter o ID do serviço da URL

    useEffect(() => {
        !localStorage.getItem("token") && navigate("/");

        // Banner
        axios
            .get(caminho.bannerapi)
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });

        // Servicos
        axios
            .get(caminho.servicoapi)
            .then((response) => {
                setServicos(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });

        // Profissionais
        axios
            .get(caminho.profissionalapi)
            .then((response) => {
                setProfissionals(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);
    const [open, setOpen] = useState(false);

    return (
        <div>
            {/* Video será reproduzido aqui */}
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
            >
                <Modal.Header
                    closeButton
                    className=" text-white"
                    onClick={() => setShow(false)}
                    style={{
                        borderBottom: "1px solid #f3b313",
                    }}
                >
                    <Modal.Title style={{ color: "#000" }}>
                        <i
                            className="fas fa-location-arrow mr-2"
                            style={{ color: "#f3b313" }}
                        ></i>
                        Onde estamos localizado
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body> O vídeo será Reproduzido aqui!</Modal.Body>

                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>

            <div class="hero  hero-slider">
                <div class="hero-slider-layout">
                    <div class="swiper">
                        <Carousel>
                            {banners.map((banner) => (
                                <Carousel.Item>
                                    <div class="swiper-slide">
                                        <div class="container">
                                            <div class="row align-items-center">
                                                <div class="col-lg-6">
                                                    <div class="hero-content">
                                                        <div class="section-title">
                                                            <h1
                                                                class="text-anime-style-2"
                                                                data-cursor="-opaque"
                                                            >
                                                                {" "}
                                                                {
                                                                    banner.tituloum
                                                                }{" "}
                                                                <span>
                                                                    {" "}
                                                                    {
                                                                        banner.titulodois
                                                                    }{" "}
                                                                </span>
                                                                {
                                                                    banner.titulotres
                                                                }{" "}
                                                            </h1>

                                                            <p
                                                                class="wow fadeInUp"
                                                                data-wow-delay="0.25s"
                                                                style={{
                                                                    textAlign:
                                                                        "justify",
                                                                }}
                                                            >
                                                                {
                                                                    banner.descricao
                                                                }
                                                            </p>
                                                        </div>

                                                        <div
                                                            class="hero-content-body wow fadeInUp"
                                                            data-wow-delay="0.5s"
                                                        >
                                                            <a
                                                                href="#"
                                                                class="btn-default"
                                                            >
                                                                5 anos de
                                                                experiência
                                                                <span>
                                                                    <i
                                                                        className="far fa-star"
                                                                        style={{
                                                                            color: "#f3b313",
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    class="col-lg-5"
                                                    style={{
                                                        marginLeft: "40px",
                                                    }}
                                                >
                                                    <div class="hero-image">
                                                        <div class="hero-img">
                                                            <figure>
                                                                <img
                                                                    // src="images/banner/extra.png"
                                                                    // src={`${process.env.PUBLIC_URL}/assets/images/banner/extra.png`}
                                                                    src={`${caminho.$meustorage}/${banner.imagem}`}
                                                                    alt=""
                                                                />
                                                            </figure>
                                                        </div>

                                                        {banner.titulotres ==
                                                        "prioridade" ? (
                                                            <div>
                                                                <div class="export-dantist-box">
                                                                    <div class="icon-box">
                                                                        <figure class="image-anime">
                                                                            <img
                                                                                // src="images/dantist-doctor-img.jpg"
                                                                                src={`${process.env.PUBLIC_URL}/assets/images/icone/doctor.png`}
                                                                                alt=""
                                                                            />
                                                                        </figure>
                                                                    </div>
                                                                    <div class="export-dantist-content">
                                                                        <h3>
                                                                            Seja
                                                                            Bem-vindo
                                                                        </h3>
                                                                        <p>
                                                                            a
                                                                            CLISPAJ
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {/* Está vazia */}
                                                            </div>
                                                        )}

                                                        {banner.iconeum ==
                                                            null ||
                                                        banner.iconedois ==
                                                            null ||
                                                        banner.iconetres ==
                                                            null ? (
                                                            <div>
                                                                {/* Está vazia */}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div class="hero-icon-list">
                                                                    <div class="hero-icon-box-1">
                                                                        <img
                                                                            // src="images/icone/laranja/1.png"
                                                                            // src={`${process.env.PUBLIC_URL}/assets/images/icone/laranja/1.png`}
                                                                            src={`${caminho.$meustorage}/${banner.iconedois}`}
                                                                            width="45"
                                                                            height="45"
                                                                            alt=""
                                                                        />
                                                                    </div>

                                                                    <div class="hero-icon-box-2">
                                                                        <img
                                                                            // src="images/icone/laranja/2.png"
                                                                            // src={`${process.env.PUBLIC_URL}/assets/images/icone/laranja/2.png`}

                                                                            src={`${caminho.$meustorage}/${banner.iconeum}`}
                                                                            width="45"
                                                                            height="45"
                                                                            alt=""
                                                                        />
                                                                    </div>

                                                                    <div class="hero-icon-box-3">
                                                                        <img
                                                                            // src="images/icone/laranja/3.png"
                                                                            // src={`${process.env.PUBLIC_URL}/assets/images/icone/laranja/3.png`}
                                                                            src={`${caminho.$meustorage}/${banner.iconetres}`}
                                                                            width="40"
                                                                            height="40"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div class="icon-star-image">
                                                            <img
                                                                src="images/icon-star.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>

            <div class="cta-box">
                <div class="container">
                    <div class="row align-items-center">
                        {/* Mobile */}

                        <div class="col-lg-4 col-md-3 col-6 disponivel">
                            <h4
                                className="text-white"
                                style={{ fontSize: "17px" }}
                            >
                                {" "}
                                Emergência
                            </h4>
                            <span class="color_orange font-weight-bold">
                                {" "}
                                +244 950 727 259{" "}
                            </span>
                        </div>

                        <div class="col-lg-4 col-md-3 col-6 disponivel">
                            <h4
                                className="text-white"
                                style={{ fontSize: "17px" }}
                            >
                                Dias funcionamento
                            </h4>
                            <span className="color_orange font-weight-bold">
                                {" "}
                                Sexta / Sábado{" "}
                            </span>
                        </div>

                        {/* FIM Mobile */}

                        <div class="col-lg-4 col-md-6 col-6 disponivel_padrao">
                            <div class="cta-box-item wow fadeInUp">
                                <div class="icon-box">
                                    <i
                                        class="fas fa-phone"
                                        // style="font-size:1.4rem; color:#f3b313;"
                                        style={{
                                            fontSize: "1.4rem",
                                            color: "#f3b313",
                                        }}
                                    ></i>
                                </div>
                                <div class="cta-box-content">
                                    <h3> Casos de emergência ?</h3>
                                    <p> +244 940 727 259/+244 950 727 259 </p>
                                    <p> </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-6 disponivel_padrao">
                            <div
                                class="cta-box-item wow fadeInUp"
                                data-wow-delay="0.25s"
                            >
                                <div class="icon-box">
                                    <i
                                        class="far fa-clock"
                                        // style="font-size:1.5rem; color:#f3b313;"
                                        style={{
                                            fontSize: "1.5rem",
                                            color: "#f3b313",
                                        }}
                                    ></i>
                                </div>
                                <div class="cta-box-content">
                                    <h3> Dias de funcionamento</h3>
                                    <p> Segunda-feira a Sábado</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 col-12">
                            <div
                                class="cta-box-btn wow fadeInUp"
                                data-wow-delay="0.5s"
                            >
                                {/* <a href="#"></a> */}
                                <Link
                                    to="/contacto"
                                    class="btn-default btn-highlighted"
                                >
                                    Entre em contacto
                                    <span>
                                        <i
                                            className="fas fa-phone-square"
                                            style={{ color: "#f3b313" }}
                                        ></i>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-us">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-image">
                                <div class="about-img-1">
                                    <figure class="image-anime ">
                                        <img
                                            // src="images/sobre/image-11.jpg"
                                            src={`${process.env.PUBLIC_URL}/assets/images/sobre/image-11.jpg`}
                                            alt=""
                                        />
                                    </figure>
                                </div>

                                <div class="about-img-2">
                                    <figure class="image-anime ">
                                        <img
                                            // src="images/sobre/2.jpg"
                                            src={`${process.env.PUBLIC_URL}/assets/images/sobre/2.jpg`}
                                            alt=""
                                        />
                                    </figure>
                                </div>

                                <div class="about-experience">
                                    <figure>
                                        {/* <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/brain1.png`}
                                            alt=""
                                        /> */}
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title">
                                    <h3 class="wow fadeInUp"></h3>
                                    <h2
                                        class="text-anime-style-2"
                                        data-cursor="-opaque"
                                    >
                                        <span></span> Sobre Nós
                                        {/* <span>Sobre</span> Nós */}
                                    </h2>
                                    <p
                                        class="wow fadeInUp"
                                        data-wow-delay="0.25s"
                                    >
                                        A CLISPAJ é uma clínica dedicada a
                                        fornecer soluções médicas completas em
                                        um só lugar. Com uma equipe de
                                        profissionais altamente qualificados e
                                        tecnologia de ponta, a CLISPAJ oferece
                                        uma ampla gama de serviços médicos,
                                        desde consultas e exames de rotina até
                                        tratamentos especializados.
                                    </p>
                                </div>

                                <div
                                    class="about-us-body wow fadeInUp"
                                    data-wow-delay="0.5s"
                                >
                                    <ul>
                                        <li>Profissionais experiente</li>
                                        <li>Serviços abrangentes</li>
                                        <li>Tecnologia de ponta</li>
                                        <li>Serviços de emergência</li>
                                    </ul>
                                </div>

                                <div
                                    class="about-us-footer wow fadeInUp"
                                    data-wow-delay="0.75s"
                                >
                                    <Link to="/sobre" class="btn-default">
                                        {" "}
                                        Saiba mais{" "}
                                        <span>
                                            <i
                                                className="far fa-eye"
                                                style={{ color: "#f3b313" }}
                                            ></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="our-services">
                <div class="container ">
                    <div class="row section-row">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h3 class="wow fadeInUp"></h3>

                                <h2
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    <span></span>Serviços
                                    {/* <span>Ser</span>viços */}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="row disponivel_padrao">
                        <div class="hero-slider-layout">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={4}
                                onSlideChange={() =>
                                    console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                            >
                                <div class="swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <div class="container">
                                                <div class="row">
                                                    {servicos.map((servico) => (
                                                        <SwiperSlide>
                                                            <div class="col-lg-12 col-md-6">
                                                                <div class="service-item wow fadeInUp">
                                                                    <div class="icon-box">
                                                                        <div class="img d-flex justify-content-center">
                                                                            <img
                                                                                // src="images/icone/servicos/clinic.png"
                                                                                // src={`${process.env.PUBLIC_URL}/assets/images/icone/servicos/clinic.png`}
                                                                                src={`${caminho.$meustorage}/${servico.icone}`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div class="service-body ">
                                                                        <h3 class="text-center">
                                                                            {
                                                                                servico.nome
                                                                            }
                                                                        </h3>
                                                                        <p class="text-center">
                                                                            {
                                                                                servico.descricao
                                                                            }{" "}
                                                                        </p>
                                                                    </div>
                                                                    <div class="read-more-btn d-flex justify-content-center">
                                                                        {/* <a href="service-single.html">
                                                                            Ler mais
                                                                        </a> */}

                                                                        <Link
                                                                            to={`/servicosingle/${servico.id}`}
                                                                        >
                                                                            Ler
                                                                            mais
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* Fim swipe */}
                            </Swiper>
                        </div>

                        <div class="col-lg-12">
                            <div
                                class="services-box-footer wow fadeInUp"
                                data-wow-delay="1s"
                            >
                                {/* <a href="#" class="btn-default">
                                    Ver todos
                                </a> */}

                                <Link to="/servico" class="btn-default">
                                    ver todos
                                    <span>
                                        <i
                                            className="far fa-eye"
                                            style={{ color: "#f3b313" }}
                                        ></i>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Responsive Mobile */}
                    <div className="disponivel">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={2}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {servicos.map((servico) => (
                                <SwiperSlide>
                                    <div className="servico_padrao d-flex justify-content-center">
                                        <div className="servico_container">
                                            <div class="icon-box">
                                                <div class="img d-flex justify-content-center">
                                                    <img
                                                        src={`${caminho.$meustorage}/${servico.icone}`}
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        className="pb-2"
                                                    />
                                                </div>
                                            </div>
                                            <div class="service-body ">
                                                <h3 class="text-center">
                                                    {servico.nome}
                                                </h3>
                                                <p class="text-center">
                                                    {servico.descricao}{" "}
                                                </p>
                                            </div>
                                            <div class="read-more-btn d-flex justify-content-center">
                                                {/* <a href="service-single.html">
                                                                            Ler mais
                                                                        </a> */}

                                                <Link
                                                    to={`/servicosingle/${servico.id}`}
                                                >
                                                    Saiba mais
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                            {/* Fim swipe */}
                        </Swiper>
                    </div>
                </div>

                <div class="intro-clinic-video disponivel_padrao">
                    <div class="container-fluid">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-9">
                                <div class="visit-clinic parallaxie">
                                    <div class="visit-clinic-content">
                                        <div class="section-title">
                                            <h2
                                                class="text-anime-style-2"
                                                data-cursor="-opaque"
                                            >
                                                {/* Nosso espeço ? */}
                                            </h2>
                                        </div>

                                        <div
                                            class="visit-clinic-btn wow fadeInUp"
                                            data-wow-delay="0.25s"
                                            data-cursor-text="Play"
                                        >
                                            <Link
                                                onClick={() => setShow(true)}
                                                // href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                                                class="popup-video play-btn"
                                                style={{ cursor: "pointer" }}
                                            >
                                                Reproduzir vídeo
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="icon-star-image">
                    <img src="images/icon-star.svg" alt="" />
                </div>
            </div>

            <div class="why-choose-us " style={{ background: "none" }}>
                <div class="container">
                    <div class="row section-row pb-5">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h3 class="wow fadeInUp"></h3>

                                <h2
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                    className="disponivel"
                                >
                                    <span> O que os pacientes </span> Dizem
                                </h2>

                                <h2
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                    className="disponivel_padrao"
                                >
                                    {/* <span> O quê que os nossos </span> pacientes
                                    Dizem? */}
                                    O quê que os nossos pacientes Dizem?
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-7 order-1">
                            <div class="why-choose-box-1 disponivel_padrao">
                                <div class="why-choose-item wow fadeInUp">
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "right",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#39f",
                                                }}
                                                className="ml-1"
                                            >
                                                {" "}
                                                <i className="fab fa-facebook"></i>
                                            </p>{" "}
                                        </span>

                                        <h3>Francisca Oliveira</h3>
                                        <p>
                                            {" "}
                                            O atendimento na clínica é
                                            excepcional. Desde a recepção até os
                                            médicos.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="why-choose-item wow fadeInUp"
                                    data-wow-delay="0.25s"
                                >
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "right",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#d62976",
                                                }}
                                                className="ml-1"
                                            >
                                                {" "}
                                                <i className="fab fa-instagram"></i>
                                            </p>{" "}
                                        </span>
                                        <h3>Tiago Oliveira Viola</h3>

                                        <p>
                                            Estou muito satisfeito com os
                                            serviços da clínica.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="why-choose-item wow fadeInUp"
                                    data-wow-delay="0.5s"
                                >
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "right",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#39f",
                                                }}
                                                className="ml-1"
                                            >
                                                {" "}
                                                <i className="fab fa-facebook"></i>
                                            </p>{" "}
                                        </span>
                                        <h3>Machado João António</h3>
                                        <p>
                                            A Clispaj superou todas as minhas
                                            expectativas. .
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 order-lg-1 order-md-2 order-1">
                            <div class="why-choose-image wow fadeInUp">
                                <figure>
                                    <img
                                        // src="images/pergunta/4.png"
                                        src={`${process.env.PUBLIC_URL}/assets/images/pergunta/4.png`}
                                        alt=""
                                        // style="margin-top:0px; max-width:none; margin-left:-70px;"
                                        className="alinhar_imagem"
                                    />
                                </figure>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 order-lg-2 order-md-1 order-2">
                            <div class="why-choose-box-2">
                                <div class="why-choose-item wow fadeInUp">
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "left",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#d62976",
                                                }}
                                                className="mr-1"
                                            >
                                                {" "}
                                                <i className="fab fa-instagram"></i>
                                            </p>{" "}
                                        </span>

                                        <h3>Tereza De Matos</h3>
                                        <p>
                                            Sou uma paciente da Clispaj há anos
                                            e sempre fui muito bem tratado.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="why-choose-item wow fadeInUp"
                                    data-wow-delay="0.25s"
                                >
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "left",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#39f",
                                                }}
                                                className="mr-1"
                                            >
                                                {" "}
                                                <i className="fab fa-facebook"></i>
                                            </p>{" "}
                                        </span>
                                        <h3> André Vasconselos </h3>
                                        <p>
                                            A equipe da Clispaj é incrível!
                                            Todos são muito simpáticos e
                                            prestativos.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="why-choose-item wow fadeInUp"
                                    data-wow-delay="0.5s"
                                >
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <span>
                                            {" "}
                                            Origem do comentário{" "}
                                            <p
                                                style={{
                                                    float: "left",
                                                    fontSize: 20,
                                                    marginTop: -5,
                                                    color: "#d62976",
                                                }}
                                                className="mr-1"
                                            >
                                                {" "}
                                                <i className="fab fa-instagram"></i>
                                            </p>{" "}
                                        </span>

                                        <h3>Luciana Almeida</h3>
                                        <p>
                                            Estou muito grata pelo atendimento
                                            que recebi na clínica Clispaj.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="why-choose-item wow fadeInUp"
                                    data-wow-delay="0.5s"
                                    className="disponivel"
                                >
                                    <div class="icon-box">
                                        <img
                                            src="images/user/user.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="why-choose-content">
                                        <h3>Machado João António</h3>
                                        <p>
                                            A Clispaj superou todas as minhas
                                            expectativas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="icon-star-image">
                    <img src="images/icon-star.svg" alt="" />
                </div>
            </div>

            <div class="our-team" style={{ padding: "40px 0 40px" }}>
                <div class="container">
                    <div class="row section-row">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h3 class="wow fadeInUp"></h3>
                                <h2
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    {/* <span>Profi</span>ssionais */}
                                    Profissionais
                                </h2>
                            </div>
                        </div>
                    </div>

                    {/* Mobile */}
                    <div class="row ">
                        {profissionals.map((profissional) => (
                            <div
                                class="col-lg-3 col-md-6 disponivel"
                                style={{
                                    width: "38%",
                                    float: "left",
                                    marginRight: "30px",
                                }}
                            >
                                <div
                                    class="team-member-item"
                                    style={{ width: "140%" }}
                                >
                                    <div class="team-image">
                                        <figure
                                            class="image-anime"
                                            style={{
                                                backgroundColor:
                                                    "hsl(0, 16%, 96%)",
                                            }}
                                        >
                                            <img
                                                // src={`${process.env.PUBLIC_URL}/assets/images/user/team-2.png`}
                                                src={`${caminho.$meustorage}/${profissional.imagem}`}
                                                alt=""
                                            />
                                        </figure>

                                        <div class="team-social-icon">
                                            {/* <ul>

                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-facebook-f"></i>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-youtube"></i>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-x-twitter"></i>
                                                    </a>
                                                </li>

                                            </ul> */}
                                        </div>
                                    </div>

                                    <div class="team-content">
                                        <h3>{profissional.nome} </h3>
                                        <p>{profissional.area} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Fim Mobile */}

                    <div class="row ">
                        {profissionals.map((profissional) => (
                            <div class="col-lg-3 col-md-6 disponivel_padrao">
                                <div class="team-member-item wow fadeInUp">
                                    <div class="team-image">
                                        <figure
                                            class="image-anime"
                                            style={{
                                                backgroundColor:
                                                    "hsl(0, 16%, 96%)",
                                            }}
                                        >
                                            <img
                                                // src={`${process.env.PUBLIC_URL}/assets/images/user/team-2.png`}
                                                src={`${caminho.$meustorage}/${profissional.imagem}`}
                                                alt=""
                                            />
                                        </figure>

                                        {/* 
                                        <div class="team-social-icon">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-youtube"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-x-twitter"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>

                                    <div class="team-content">
                                        <h3>{profissional.nome} </h3>
                                        <p>{profissional.area} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div class="col-lg-12">
                        <div
                            class="services-box-footer wow fadeInUp"
                            data-wow-delay="1s"
                        >
                            {/* <a href="#" class="btn-default">
                                    Ver todos
                                </a> */}

                            <Link to="/profissional" class="btn-default">
                                ver todos
                                <span>
                                    <i
                                        className="far fa-eye"
                                        style={{ color: "#f3b313" }}
                                    ></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="icon-star-image">
                    <img src="images/icon-star.svg" alt="" />
                </div>
            </div>

            <div class="contact-now">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="contact-google-map">
                                <div class="google-map-iframe">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.674288428461!2d13.231806075969057!3d-8.909841491452797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f565f1d3b797%3A0x18e1f67b1d926486!2sClinica%20ISPAJ!5e0!3m2!1spt-PT!2sao!4v1713786600318!5m2!1spt-PT!2sao"
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="contact-now-content">
                                <div class="section-title">
                                    <h3 class="wow fadeInUp"></h3>
                                    <h2
                                        class="text-anime-style-2"
                                        data-cursor="-opaque"
                                    >
                                        <span>Onde </span> estamos ?
                                    </h2>
                                </div>

                                <div class="contact-now-info">
                                    <div
                                        class="contact-info-list wow fadeInUp"
                                        data-wow-delay="0.2s"
                                    >
                                        <div class="icon-box">
                                            <i
                                                class="fas fa-location-arrow"
                                                style={{
                                                    fontSize: "1.3rem",
                                                    color: "#f3b313",
                                                }}
                                            ></i>
                                        </div>

                                        <div class="contact-info-content">
                                            <p>
                                                {" "}
                                                Projecto nova vida, ISPAJ rua 45{" "}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        class="contact-info-list wow fadeInUp"
                                        data-wow-delay="0.4s"
                                    >
                                        <div class="icon-box">
                                            <i
                                                class="fas fa-phone"
                                                style={{
                                                    fontSize: "1.3rem",
                                                    color: "#f3b313",
                                                }}
                                            ></i>
                                        </div>

                                        <div class="contact-info-content">
                                            <p>
                                                +244 950 727 259/+244 940 727
                                                259
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        class="contact-info-list wow fadeInUp"
                                        data-wow-delay="0.6s"
                                    >
                                        <div class="icon-box">
                                            <i
                                                class="far fa-comment-dots"
                                                style={{
                                                    fontSize: "1.3rem",
                                                    color: "#f3b313",
                                                }}
                                            ></i>
                                        </div>

                                        <div class="contact-info-content">
                                            <p>clinicaispaj@gmail.com</p>
                                        </div>
                                    </div>

                                    <div
                                        class="contact-info-list wow fadeInUp disponivel_padrao"
                                        data-wow-delay="0.8s"
                                    >
                                        <div class="icon-box">
                                            <i
                                                class="far fa-clock"
                                                style={{
                                                    fontSize: "1.3rem",
                                                    color: "#f3b313",
                                                }}
                                            ></i>
                                        </div>

                                        <div class="contact-info-content">
                                            <p>
                                                Segunda - Sexta das 8H00 - 18H00
                                                to 07H00 - 12H00
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
