import React, { useEffect, useState } from "react";
// Import Swiper React components
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const Sobre = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // $("#myElement").text("Novo texto alterado com jQuery!");
    }, []);

    return (
        <div>
            {/* Video será reproduzido aqui */}
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
            >
                <Modal.Header
                    closeButton
                    className=" text-white"
                    onClick={() => setShow(false)}
                    style={{
                        borderBottom: "1px solid #f3b313",
                    }}
                >
                    {/* <Modal.Title style={{ color: "#000" }}>
                        <i
                            className="fas fa-location-arrow mr-2"
                            style={{ color: "#f3b313" }}
                        ></i>
                        Onde estamos localizado
                    </Modal.Title> */}
                </Modal.Header>

                <Modal.Body> O vídeo será Reproduzido aqui!</Modal.Body>

                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>

            <div class="page-header" style={{ padding: "30px 0" }}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="page-header-box">
                                <h1
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    <span>Sobre</span> Nós
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-us">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-image">
                                <div class="about-img-1">
                                    <figure class="image-anime ">
                                        <img
                                            // src="images/sobre/image-11.jpg"
                                            src={`${process.env.PUBLIC_URL}/assets/images/sobre/image-11.jpg`}
                                            alt=""
                                        />
                                    </figure>
                                </div>

                                <div class="about-img-2">
                                    <figure class="image-anime ">
                                        <img
                                            // src="images/sobre/2.jpg" alt=""
                                            src={`${process.env.PUBLIC_URL}/assets/images/sobre/2.jpg`}
                                        />
                                    </figure>
                                </div>

                                <div class="about-experience">
                                    <figure>
                                        <img
                                            // src="images/about-experience-circle.png"
                                            // src={`${process.env.PUBLIC_URL}/assets/images/about-experience-circle.png`}
                                            alt=""
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title">
                                    <h3 class="wow fadeInUp"></h3>

                                    <p
                                        class="wow fadeInUp"
                                        data-wow-delay="0.25s"
                                        style={{ textAlign: "justify" }}
                                    >
                                        A Clispaj é uma clínica dedicada a
                                        fornecer soluções médicas completas em
                                        um só lugar. Com uma equipe de
                                        profissionais altamente qualificados e
                                        tecnologia de ponta, a Clispaj oferece
                                        uma ampla gama de serviços médicos,
                                        desde consultas e exames de rotina até
                                        tratamentos especializados e cirurgias.
                                        Nossa missão na Clispaj é promover a
                                        saúde e o bem-estar dos nossos
                                        pacientes, proporcionando cuidados
                                        médicos personalizados e eficazes.
                                        Valorizamos a excelência clínica, o
                                        atendimento compassivo e a busca
                                        contínua pela inovação na medicina Entre
                                        em contato conosco para agendar uma
                                        consulta e descubra como a Clispaj pode
                                        cuidar da sua saúde de forma abrangente
                                        e integrada. Estamos aqui para ajudá-lo
                                        a alcançar uma vida mais saudável e
                                        feliz.
                                    </p>
                                </div>

                                <div
                                    class="about-us-body wow fadeInUp"
                                    data-wow-delay="0.5s"
                                >
                                    <ul>
                                        <li>Profissionais experiente</li>
                                        <li>Serviços abrangentes</li>
                                        <li>Tecnologia de ponta</li>
                                        <li>Serviços de emergência</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="intro-clinic-video disponivel_padrao">
                <div class="container-fluid">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-9">
                            <div class="visit-clinic parallaxie">
                                <div class="visit-clinic-content">
                                    <div class="section-title">
                                        <h2
                                            class="text-anime-style-2"
                                            data-cursor="-opaque"
                                        >
                                            {/* Nosso espeço ? */}
                                        </h2>
                                    </div>

                                    <div
                                        class="visit-clinic-btn wow fadeInUp"
                                        data-wow-delay="0.25s"
                                        data-cursor-text="Play"
                                    >
                                        <Link
                                            onClick={() => setShow(true)}
                                            // href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                                            class="popup-video play-btn"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Reproduzir vídeo
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sobre;
