import React, { useEffect, useState } from "react";
import axios from "axios";
import caminho from "../services/Api";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css"; // Certifique-se de que o Bootstrap está importado

const GaleriaLista = (props) => {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [galerias, setServicos] = useState([]);

    useEffect(() => {
        // Fetch data
        axios
            .get(caminho.galeriaapi)
            .then((response) => {
                setServicos(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);

    const handleShow = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return (
        <div>
            <div className="page-header" style={{ padding: "30px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-header-box">
                                <h1
                                    className="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    <span> Gale</span>ria{" "}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-gallery-page">
                <div className="container">
                    <div className="row gallery-items">
                        {galerias.map((galeria) => (
                            <div
                                className="col-lg-3 col-md-4 col-6"
                                key={galeria.id}
                            >
                                <div
                                    className="photo-gallery wow fadeInUp"
                                    data-cursor-text="View"
                                    onClick={() =>
                                        handleShow(
                                            `${caminho.$meustorage}/${galeria.imagem}`
                                        )
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    <figure>
                                        <img
                                            src={`${caminho.$meustorage}/${galeria.imagem}`}
                                            alt=""
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    </figure>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Imagem da Galeria</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <img
                        src={selectedImage}
                        alt="Imagem da Galeria"
                        style={{ width: "100%" }}
                    />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
};

export default GaleriaLista;
