
const API_URL = "http://10.10.10.36:8000/api/auth"; // Adjust URL as per your Laravel setup

class AuthService {
    async login(email, password) {
        const response = await fetch(`${API_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || "Failed to login");
        }
        const data = await response.json();
        localStorage.setItem("token", data.authorisation.token); // Assuming your API returns a token
        return data;
    }

    isLoggedIn() {
        return !!localStorage.getItem("token");
    }

    async logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        window.location.href = "/login"; // Redirect to login page after logout
    }
    // Add other methods like register, refreshToken if needed
}

export default new AuthService();
