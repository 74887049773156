import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Sobre from "./pages/Sobre";
import Servico from "./pages/Service";
import Profissional from "./pages/Profissional";
import Contacto from "./pages/Contacto";
import Horario from "./pages/Horario";
import Login from "./auth/authentication";
import Admin from "./pages/Admin/admin";
import ServicoIndex from "./pages/Admin/servicos";
import ServicoCreate from "./pages/Admin/servicos/create";
import ServicoEdit from "./pages/Admin/servicos/edit";
import ProfissionalIndex from "./pages/Admin/profissionais";
import ProfissionalEdit from "./pages/Admin/profissionais/edit";
import ProfissionalCreate from "./pages/Admin/profissionais/create";
import GaleriaIndex from "./pages/Admin/galerias";
import GaleriaCreate from "./pages/Admin/galerias/create";
import GaleriaEdit from "./pages/Admin/galerias/edit";
import BannerIndex from "./pages/Admin/banners";
import BannerCreate from "./pages/Admin/banners/create";
import BannerEdit from "./pages/Admin/banners/edit";
import ContactoIndex from "./pages/Admin/contactos";
import ContactoCreate from "./pages/Admin/contactos/create";
import ContactoEdit from "./pages/Admin/contactos/edit";
import ServicoSingle from "./pages/Servicesingle";
import GaleriaLista from "./pages/Galeria";

// import AuthService from "./services/Authservice";

const App = () => {
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token"));

    useEffect(() => {
        // async function checkLoggedIn() {
        //   // alert(loggedIn);
        //   // setIsLoggedIn(loggedIn);
        // }
        // checkLoggedIn();
        setLoggedIn(localStorage.getItem("token") ? true : false);
    });

    return (
        <Router>
            <Header />

            <Routes>
                <Route path="/login" element={<Login />} />

                <Route path="/" element={<Home />} />

                <Route path="/" element={<Home />} />
                <Route path="/sobre" element={<Sobre />} />
                <Route path="/servico" element={<Servico />} />
                <Route path="/profissional" element={<Profissional />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/horario" element={<Horario />} />
                <Route path="/admin" element={<Admin />} />

                {/* Servico */}
                <Route path="/admin/servicoindex" element={<ServicoIndex />} />
                <Route
                    path="/admin/servicocreate"
                    element={<ServicoCreate />}
                />
                <Route
                    path="/admin/servicoedit/:id"
                    element={<ServicoEdit />}
                />

                <Route path="/admin/servicoindex" element={<ServicoIndex />} />
                <Route
                    path="/admin/servicocreate"
                    element={<ServicoCreate />}
                />
                <Route
                    path="/admin/servicoedit/:id"
                    element={<ServicoEdit />}
                />

                <Route path="/servicosingle/:id" element={<ServicoSingle />} />

                {/* Profissionais */}
                <Route
                    path="/admin/profissionalindex"
                    element={<ProfissionalIndex />}
                />
                <Route
                    path="/admin/profissionalcreate"
                    element={<ProfissionalCreate />}
                />

                <Route
                    path="/admin/profissionaledit/:id"
                    element={<ProfissionalEdit />}
                />

                {/* Galeria */}
                <Route path="/admin/galeriaindex" element={<GaleriaIndex />} />
                <Route
                    path="/admin/galeriacreate"
                    element={<GaleriaCreate />}
                />
                <Route
                    path="/admin/galeriaedit/:id"
                    element={<GaleriaEdit />}
                />

                {/* Banner */}
                <Route path="/admin/bannerindex" element={<BannerIndex />} />
                <Route path="/admin/bannercreate" element={<BannerCreate />} />
                <Route path="/admin/banneredit/:id" element={<BannerEdit />} />

                {/* Contactos */}
                <Route
                    path="/admin/contactoindex"
                    element={<ContactoIndex />}
                />
                <Route
                    path="/admin/contactocreate"
                    element={<ContactoCreate />}
                />
                <Route
                    path="/admin/contactoedit/:id"
                    element={<ContactoEdit />}
                />
                {/* Galeria */}
                <Route path="/galeria" element={<GaleriaLista />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
