import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
const SideBarAdmin = () => {
  return (
      <div id="left-sidebar" class="sidebar">
          <div class="">
              <ul class="nav nav-tabs">
                  <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#menu">
                          Menu
                      </a>
                  </li>
              </ul>

              <div class="tab-content p-l-0 p-r-0">
                  <div class="tab-pane active" id="menu">
                      <nav id="left-sidebar-nav" class="sidebar-nav">
                          <Accordion>
                              <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                      {" "}
                                      <i
                                          className="fas fa-cubes mr-2"
                                          style={{
                                              color: "#f3b313",
                                          }}
                                      ></i>{" "}
                                      Serviços{" "}
                                  </Accordion.Header>

                                  <Accordion.Body>
                                      <Link
                                          to="/admin/servicocreate"
                                          style={{ color: "#555" }}
                                      >
                                          {" "}
                                          <span>Registar</span>{" "}
                                      </Link>

                                      <br />
                                      <span>
                                          {" "}
                                          <Link
                                              to="/admin/servicoindex"
                                              className="mt-2"
                                              style={{
                                                  color: "#555",
                                              }}
                                          >
                                              Visualizar
                                          </Link>
                                      </span>
                                  </Accordion.Body>
                              </Accordion.Item>

                              <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                      {" "}
                                      <i
                                          className="fas fa-users mr-2"
                                          style={{
                                              color: "#f3b313",
                                          }}
                                      ></i>{" "}
                                      Profissionais{" "}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                      <Link
                                          to="/admin/profissionalcreate"
                                          style={{ color: "#555" }}
                                      >
                                          {" "}
                                          <span>Registar</span>{" "}
                                      </Link>

                                      <br />
                                      <span>
                                          {" "}
                                          <Link
                                              to="/admin/profissionalindex"
                                              className="mt-2"
                                              style={{
                                                  color: "#555",
                                              }}
                                          >
                                              Visualizar
                                          </Link>
                                      </span>
                                  </Accordion.Body>

                                  {/* <Accordion.Body>Remover</Accordion.Body> */}
                              </Accordion.Item>

                              <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                      {" "}
                                      <i
                                          className="far fa-images mr-2"
                                          style={{
                                              color: "#f3b313",
                                          }}
                                      ></i>{" "}
                                      Galerias{" "}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                      <Link
                                          to="/admin/galeriacreate"
                                          style={{ color: "#555" }}
                                      >
                                          {" "}
                                          <span>Registar</span>{" "}
                                      </Link>

                                      <br />
                                      <span>
                                          {" "}
                                          <Link
                                              to="/admin/galeriaindex"
                                              className="mt-2"
                                              style={{
                                                  color: "#555",
                                              }}
                                          >
                                              Visualizar
                                          </Link>
                                      </span>
                                  </Accordion.Body>

                                  {/* <Accordion.Body>Remover</Accordion.Body> */}
                              </Accordion.Item>

                              <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                      {" "}
                                      <i
                                          className="far fa-images mr-2"
                                          style={{
                                              color: "#f3b313",
                                          }}
                                      ></i>{" "}
                                      Banners{" "}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                      <Link
                                          to="/admin/bannercreate"
                                          style={{ color: "#555" }}
                                      >
                                          {" "}
                                          <span>Registar</span>{" "}
                                      </Link>

                                      <br />
                                      <span>
                                          {" "}
                                          <Link
                                              to="/admin/bannerindex"
                                              className="mt-2"
                                              style={{
                                                  color: "#555",
                                              }}
                                          >
                                              Visualizar
                                          </Link>
                                      </span>
                                  </Accordion.Body>

                                  {/* <Accordion.Body>Remover</Accordion.Body> */}
                              </Accordion.Item>
                              <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                      {" "}
                                      <i
                                          className="fas fa-phone-square mr-2"
                                          style={{
                                              color: "#f3b313",
                                          }}
                                      ></i>{" "}
                                      Contactos{" "}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                      <Link
                                          to="/admin/contactocreate"
                                          style={{
                                              color: "#555",
                                          }}
                                      >
                                          {" "}
                                          <span>Registar</span>{" "}
                                      </Link>

                                      <br />
                                      <span>
                                          {" "}
                                          <Link
                                              to="/admin/contactoindex"
                                              className="mt-2"
                                              style={{
                                                  color: "#555",
                                              }}
                                          >
                                              Visualizar
                                          </Link>
                                      </span>
                                  </Accordion.Body>

                                  {/* <Accordion.Body>Remover</Accordion.Body> */}
                              </Accordion.Item>
                          </Accordion>
                      </nav>
                  </div>
              </div>
          </div>
      </div>
  );


};

export default SideBarAdmin;
