import React, { useEffect, useState } from "react";
// Import Swiper React components
import caminho from "../services/Api";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Contacto = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [nome, setNome] = useState("");
    const [imail, setEmail] = useState("");
    const [numero, setNumero] = useState("");
    const [assunto, setAssunto] = useState("");
    const [descricao, setDescricao] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("imail", imail);
        formData.append("numero", numero);
        formData.append("assunto", assunto);
        formData.append("descricao", descricao);

        try {
            const response = await axios.post(caminho.contactoapi, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                // alert("Serviço adicionado com sucesso!")
                navigate("/contacto");
                Swal.fire({
                    title: "Sucesso!",
                    text: "Dados envido  com sucesso!",
                    icon: "success",
                    confirmButtonText: "Sim",
                });
            } else {
                alert("Erro ao adicionar serviço.");
            }
        } catch (error) {
            console.error("Erro ao adicionar serviço", error);
            alert("Erro ao adicionar serviço.");
        }
    };

    return (
        <div>
            <div class="page-header" style={{ padding: "30px 0" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-header-box">
                                <h1
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    Conta<span>ctos</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-contact">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="contact-us-content">
                                <div class="section-title">
                                    <h3 class="wow fadeInUp"></h3>
                                    <h2
                                        class="text-anime-style-2"
                                        data-cursor="-opaque"
                                    >
                                        <span>Informações</span> Gerais
                                    </h2>
                                </div>

                                {/* Mobile */}
                                <div class="contact-us-info disponivel">
                                    <div class="row ">
                                        <div
                                            class="servico_padrao d-flex justify-content-center ml-3"
                                            style={{
                                                boxShadow:
                                                    "0px 2px 10px 0px #0000000f",
                                            }}
                                        >
                                            <div className="servico_container">
                                                <div
                                                    class="icon-box circle-efect"
                                                    style={{
                                                        marginLeft: "40px",
                                                    }}
                                                >
                                                    <i class="fas fa-location-arrow cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        visite-nos
                                                    </h3>
                                                    <p className="text-center">
                                                        Projecto nova vida,
                                                        ISPAJ rua 45,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="servico_padrao  d-flex justify-content-center"
                                            style={{
                                                boxShadow:
                                                    "0px 2px 10px 0px #0000000f",
                                            }}
                                        >
                                            <div className="servico_container">
                                                <div
                                                    class="icon-box circle-efect"
                                                    style={{
                                                        marginLeft: "40px",
                                                    }}
                                                >
                                                    <i class="fas fa-phone cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        Telefone
                                                    </h3>
                                                    <p className="text-center">
                                                        +244 940 727 259
                                                    </p>
                                                    <p className="text-center">
                                                        +244 950 727 259
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="servico_padrao  d-flex justify-content-center ml-3"
                                            style={{
                                                boxShadow:
                                                    "0px 2px 10px 0px #0000000f",
                                            }}
                                        >
                                            <div className="servico_container">
                                                <div
                                                    class="icon-box circle-efect"
                                                    style={{
                                                        marginLeft: "40px",
                                                    }}
                                                >
                                                    <i class="far fa-clock cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        Horário de funcionamento
                                                    </h3>
                                                    <p className="text-center">
                                                        Segunda-Sexta : 07:00 a
                                                        19:00
                                                    </p>
                                                    <p className="text-center">
                                                        Sábado : 07:00 a 12:00
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="servico_padrao  d-flex justify-content-center"
                                            style={{
                                                boxShadow:
                                                    "0px 2px 10px 0px #0000000f",
                                            }}
                                        >
                                            <div className="servico_container">
                                                <div
                                                    class="icon-box circle-efect"
                                                    style={{
                                                        marginLeft: "40px",
                                                    }}
                                                >
                                                    <i class="fas fa-phone cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        Email
                                                    </h3>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Clinicaispaj@gmail.com
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Mobile */}

                                <div class="contact-us-info disponivel_padrao">
                                    <div class="row">
                                        <div class="col-md-6 ">
                                            <div class="contact-us-item wow fadeInUp ">
                                                <div
                                                    class="icon-box"
                                                    style={{
                                                        backgroundColor:
                                                            "#f3b313",
                                                        marginLeft: "80px",
                                                    }}
                                                >
                                                    <i class="fas fa-location-arrow cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content ">
                                                    <h3 className="d-flex justify-content-center">
                                                        visite-nos
                                                    </h3>
                                                    <p className="text-center">
                                                        Projecto nova vida,
                                                        ISPAJ rua 45,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div
                                                class="contact-us-item wow fadeInUp"
                                                data-wow-delay="0.25s"
                                            >
                                                <div
                                                    class="icon-box cor_padrao"
                                                    style={{
                                                        backgroundColor:
                                                            "#f3b313",
                                                        marginLeft: "80px",
                                                    }}
                                                >
                                                    <i class="fas fa-phone cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="d-flex justify-content-center">
                                                        Telefone
                                                    </h3>
                                                    <p className="text-center">
                                                        +244 940 727 259
                                                    </p>
                                                    <p className="text-center">
                                                        +244 950 727 259
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div
                                                class="contact-us-item wow fadeInUp"
                                                data-wow-delay="0.5s"
                                            >
                                                <div
                                                    class="icon-box cor_padrao"
                                                    style={{
                                                        backgroundColor:
                                                            "#f3b313",
                                                        marginLeft: "80px",
                                                    }}
                                                >
                                                    <i class="far fa-clock cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        Horário de funcionamento
                                                    </h3>
                                                    <p className="text-center">
                                                        Segunda-Sexta : 07:00 a
                                                        19:00
                                                    </p>
                                                    <p className="text-center">
                                                        Sábado : 07:00 a 12:00
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div
                                                class="contact-us-item wow fadeInUp"
                                                data-wow-delay="0.75s"
                                            >
                                                <div
                                                    class="icon-box cor_padrao"
                                                    style={{
                                                        backgroundColor:
                                                            "#f3b313",
                                                        marginLeft: "80px",
                                                    }}
                                                >
                                                    <i class="far fa-comment-dots cor_icone_tamanho"></i>
                                                </div>

                                                <div class="contact-info-content">
                                                    <h3 className="text-center">
                                                        Email
                                                    </h3>
                                                    <p className="text-center">
                                                        clinicaispaj@gmail.com
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="google-map">
                                <div class="google-map-iframe">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.674288428461!2d13.231806075969057!3d-8.909841491452797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f565f1d3b797%3A0x18e1f67b1d926486!2sClinica%20ISPAJ!5e0!3m2!1spt-PT!2sao!4v1713786600318!5m2!1spt-PT!2sao"
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-us-form">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="contact-us-img">
                                <figure class="">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/sobre/contacto.jpg`}
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="contact-form">
                                <div class="section-title">
                                    <h3 class="wow fadeInUp"></h3>
                                    <h2
                                        class="text-anime-style-2"
                                        data-cursor="-opaque"
                                    >
                                        <span>Entre</span> em contacto
                                    </h2>
                                </div>

                                {/* <form id="contactForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp" data-wow-delay="0.25s"> */}
                                <form
                                    id="contactForm"
                                    method="POST"
                                    data-toggle="validator"
                                    class="wow fadeInUp"
                                    data-wow-delay="0.25s"
                                    onSubmit={handleSubmit}
                                >
                                    <div class="row">
                                        <div class="form-group col-md-6 mb-4">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="fullname"
                                                placeholder="Seu nome"
                                                value={nome}
                                                onChange={(e) =>
                                                    setNome(e.target.value)
                                                }
                                                required=""
                                            />
                                            <div class="help-block with-errors"></div>
                                        </div>

                                        <div class="form-group col-md-6 mb-4">
                                            <input
                                                type="emal"
                                                class="form-control"
                                                id="imail"
                                                placeholder="Seu email"
                                                value={imail}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                required=""
                                            />
                                            <div class="help-block with-errors"></div>
                                        </div>

                                        <div class="form-group col-md-6 mb-4">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="phone"
                                                placeholder="Seu número de telemóvel"
                                                required=""
                                                value={numero}
                                                onChange={(e) =>
                                                    setNumero(e.target.value)
                                                }
                                            />
                                            <div class="help-block with-errors"></div>
                                        </div>

                                        <div class="form-group col-md-6 mb-4">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="subject"
                                                placeholder="Assunto"
                                                required=""
                                                value={assunto}
                                                onChange={(e) =>
                                                    setAssunto(e.target.value)
                                                }
                                            />
                                            <div class="help-block with-errors"></div>
                                        </div>

                                        <div class="form-group col-md-12 mb-5">
                                            <textarea
                                                class="form-control"
                                                id="msg"
                                                rows="5"
                                                placeholder="Mensagem"
                                                required=""
                                                value={descricao}
                                                onChange={(e) =>
                                                    setDescricao(e.target.value)
                                                }
                                            ></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>

                                        <div class="col-md-12">
                                            <button
                                                type="submit"
                                                class="btn-default"
                                            >
                                                Enviar
                                            </button>
                                            <div
                                                id="msgSubmit"
                                                class="h3 hidden"
                                            ></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacto;
