import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header";
import axios from "axios";
import Swal from "sweetalert2";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import SideBarAdmin from "../layouts/SideBarAdmin";
import caminho from "../../../services/Api";

const ServicoEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams(); // Obter o ID do serviço da URL

    const [nome, setNome] = useState("");
    const [icone, setIcone] = useState("");
    const [imagem, setImagem] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    // alert(id);

    useEffect(() => {
        if (id) {
            // Se houver um ID, estamos no modo de edição
            setIsEditMode(true);
            // Buscar os dados do serviço existente
            axios
                .get(`${caminho.editservicosevidorapi}t/${id}`)
                .then((response) => {
                    const servico = response.data.servico;
                    setNome(servico.nome);
                    setIcone(servico.icone);
                    setDescricao(servico.descricao);
                    console.log("res", servico);
                    // Nota: A imagem pode não ser inicializada aqui,
                    // depende de como você deseja lidar com ela
                })
                .catch((error) => {
                    console.error("Erro ao buscar serviço", error);
                    alert("Erro ao buscar serviço.");
                });
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("icone", icone);
        formData.append("imagem", imagem);
        formData.append("descricao", descricao);

        try {
            let response;
            if (isEditMode) {
                // Atualizar serviço existente
                response = await axios.post(
                    `${caminho.updateservicosevidorapi}/${id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
            } else {
                // Criar novo serviço
                response = await axios.post(
                    caminho.storeservicosevidorapi,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
            }

            if (response.status === 200) {
                alert(
                    isEditMode
                        ? "Serviço atualizado com sucesso!"
                        : "Serviço adicionado com sucesso!"
                );
                navigate("/admin/servicoindex");
            } else {
                alert(
                    isEditMode
                        ? "Erro ao atualizar serviço."
                        : "Erro ao adicionar serviço."
                );
            }
        } catch (error) {
            console.error(
                isEditMode
                    ? "Erro ao atualizar serviço"
                    : "Erro ao adicionar serviço",
                error
            );
            alert(
                isEditMode
                    ? "Erro ao atualizar serviço."
                    : "Erro ao adicionar serviço."
            );
        }
    };

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>

            <div id="wrapper">
                <SideBarAdmin />

                <div id="main-content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <h2 style={{ textIndent: "410px" }}>
                                        Editar Serviço
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix d-flex justify-content-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="header">
                                        {/* <h2>Adicionar serviços</h2> */}
                                    </div>
                                    <div className="body">
                                        <form
                                            id="basic-form"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Nome</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            value={nome}
                                                            onChange={(e) =>
                                                                setNome(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Imagem</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) =>
                                                                setImagem(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Icone</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) =>
                                                                setIcone(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-4">
                                                <label>Descrição</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    required
                                                    value={descricao}
                                                    onChange={(e) =>
                                                        setDescricao(
                                                            e.target.value
                                                        )
                                                    }
                                                ></textarea>
                                            </div>

                                            <br />
                                            <button
                                                type="submit"
                                                className="btn btn-primary cor_padrao"
                                            >
                                                Registar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicoEdit;
