import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header";
import AuthService from "../../services/Authservice"; // Adjust the import according to your file structure

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import SideBarAdmin from "./layouts/SideBarAdmin";

const Admin = () => {
    const location = useLocation();

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>

            <div id="wrapper">
           

                <SideBarAdmin />

                <div id="main-content">
                    <div class="container-fluid">
                        <div class="block-header">
                            <div class="row">
                                <div class="col-lg-5 col-md-8 col-sm-12">
                                    <h2>Painel</h2>
                                </div>
                                {/* <div class="col-lg-7 col-md-4 col-sm-12 text-right"></div> */}
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="card overflowhidden number-chart">
                                    <div class="body">
                                        <div class="number">
                                            <h6>Serviços</h6>
                                        </div>
                                    </div>

                                    <div
                                        class="sparkline"
                                        data-type="line"
                                        data-spot-Radius="0"
                                        data-offset="90"
                                        data-width="100%"
                                        data-height="50px"
                                        data-line-Width="1"
                                        data-line-Color="#f79647"
                                        data-fill-Color="#fac091"
                                    >
                                        1,4,1,3,7,1
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="card overflowhidden number-chart">
                                    <div class="body">
                                        <div class="number">
                                            <h6>Profissionais</h6>
                                        </div>
                                    </div>
                                    <div
                                        class="sparkline"
                                        data-type="line"
                                        data-spot-Radius="0"
                                        data-offset="90"
                                        data-width="100%"
                                        data-height="50px"
                                        data-line-Width="1"
                                        data-line-Color="#604a7b"
                                        data-fill-Color="#a092b0"
                                    >
                                        1,4,2,3,6,2
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="card overflowhidden number-chart">
                                    <div class="body">
                                        <div class="number">
                                            <h6>Galerias</h6>
                                        </div>
                                    </div>
                                    <div
                                        class="sparkline"
                                        data-type="line"
                                        data-spot-Radius="0"
                                        data-offset="90"
                                        data-width="100%"
                                        data-height="50px"
                                        data-line-Width="1"
                                        data-line-Color="#4aacc5"
                                        data-fill-Color="#92cddc"
                                    >
                                        1,4,2,3,1,5
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="card overflowhidden number-chart">
                                    <div class="body">
                                        <div class="number">
                                            <h6>Banners</h6>
                                        </div>
                                    </div>
                                    <div
                                        class="sparkline"
                                        data-type="line"
                                        data-spot-Radius="0"
                                        data-offset="90"
                                        data-width="100%"
                                        data-height="50px"
                                        data-line-Width="1"
                                        data-line-Color="#4f81bc"
                                        data-fill-Color="#95b3d7"
                                    >
                                        1,3,5,1,4,2
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Admin;
