import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "../pages/Admin/layouts/headerAdmin";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

const Header = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        !localStorage.getItem("token") && navigate("/login");
        // $("#myElement").text("Novo texto alterado com jQuery!");
    }, []);

    const [open, setOpen] = useState(false);
    return (
        <>
            {!location.pathname.includes("admin") ? (
                <div>
                    <header class="main-header">
                        <div class="header-sticky">
                            <nav class="navbar navbar-expand-lg">
                                <div class="container">
                                    <Link
                                        to="/"
                                        class="navbar-brand disponivel ml-3"
                                    >
                                        <img
                                            // src=""
                                            src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.jpg`}
                                            width="50"
                                            height="50"
                                            alt="Logo"
                                        />
                                        <span class="m-2 ml-2">CLISPAJ</span>
                                    </Link>

                                    <Link
                                        to="/"
                                        class="navbar-brand disponivel_padrao"
                                    >
                                        <img
                                            // src=""
                                            src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.jpg`}
                                            width="50"
                                            height="50"
                                            alt="Logo"
                                        />
                                        <span class="m-2 ml-2">CLISPAJ</span>
                                    </Link>

                                    <div class="collapse navbar-collapse main-menu">
                                        <div class="nav-menu-wrapper">
                                            <ul
                                                class="navbar-nav mr-auto"
                                                id="menu"
                                            >
                                                <li class="nav-item ">
                                                    <Link to="/">Início</Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to="/sobre">
                                                        Sobre nós
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to="/servico">
                                                        Serviços
                                                    </Link>
                                                </li>

                                                <li class="nav-item ">
                                                    <Link to="/profissional">
                                                        Profissionais
                                                    </Link>
                                                </li>
                                                <li class="nav-item ">
                                                    <Link to="/galeria">
                                                        Galeria
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to="/contacto">
                                                        Contactos
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="header-btn d-inline-flex">
                                            <Link
                                                class="btn-default text-white"
                                                onClick={() => setShow(true)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Horários de funcionamento
                                                <span>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            color: "#f3b313",
                                                        }}
                                                    ></i>
                                                </span>
                                            </Link>

                                            <Modal
                                                {...props}
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                                show={show}
                                            >
                                                <Modal.Header
                                                    closeButton
                                                    className=" text-white"
                                                    onClick={() =>
                                                        setShow(false)
                                                    }
                                                    style={{
                                                        borderBottom:
                                                            "1px solid #f3b313",
                                                    }}
                                                >
                                                    <Modal.Title
                                                        id="contained-modal-title-vcenter"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        <i
                                                            className="far fa-clock mr-2"
                                                            style={{
                                                                color: "#f3b313",
                                                            }}
                                                        ></i>
                                                        Horário de funcionamento
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div
                                                        class="servico_padrao d-flex justify-content-center ml-3"
                                                        style={{
                                                            boxShadow:
                                                                "0px 2px 10px 0px #0000000f",
                                                            width: "170px",
                                                            height: "200px",
                                                        }}
                                                    >
                                                        <div className="servico_container">
                                                            <div
                                                                class="icon-box circle-efect"
                                                                style={{
                                                                    marginLeft:
                                                                        "40px",
                                                                    background:
                                                                        "none",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/icone/horarios/emergencia.png`}
                                                                />
                                                            </div>

                                                            <div class="contact-info-content">
                                                                <h4 className="text-center">
                                                                    Emergência
                                                                </h4>
                                                                <p className="text-center">
                                                                    24 / 24
                                                                    Horas ao dia
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Consulta */}
                                                    <div
                                                        class="servico_padrao d-flex justify-content-center ml-3"
                                                        style={{
                                                            boxShadow:
                                                                "0px 2px 10px 0px #0000000f",
                                                            width: "170px",
                                                            height: "200px",
                                                        }}
                                                    >
                                                        <div className="servico_container">
                                                            <div
                                                                class="icon-box circle-efect"
                                                                style={{
                                                                    marginLeft:
                                                                        "40px",
                                                                    background:
                                                                        "none",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/icone/horarios/consulta.png`}
                                                                />
                                                            </div>

                                                            <div class="contact-info-content">
                                                                <h4 className="text-center">
                                                                    Consulta
                                                                </h4>
                                                                <p className="text-center">
                                                                    Segunda-Sexta
                                                                    8H00 - 18H00
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Fim consulta */}

                                                    {/* Unidade */}
                                                    <div
                                                        class="servico_padrao d-flex justify-content-center ml-3"
                                                        style={{
                                                            boxShadow:
                                                                "0px 2px 10px 0px #0000000f",
                                                            width: "170px",
                                                            height: "200px",
                                                        }}
                                                    >
                                                        <div className="servico_container">
                                                            <div
                                                                class="icon-box circle-efect"
                                                                style={{
                                                                    marginLeft:
                                                                        "40px",
                                                                    background:
                                                                        "none",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/icone/horarios/unidade.png`}
                                                                />
                                                            </div>

                                                            <div class="contact-info-content">
                                                                <h4 className="text-center">
                                                                    Unidade
                                                                </h4>
                                                                <p className="text-center">
                                                                    Segunda-Sexta
                                                                    07H00 -
                                                                    19H30
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Fim unidade */}

                                                    {/* Sábado */}
                                                    <div
                                                        class="servico_padrao d-flex justify-content-center ml-3"
                                                        style={{
                                                            boxShadow:
                                                                "0px 2px 10px 0px #0000000f",
                                                            width: "150px",
                                                            height: "200px",
                                                        }}
                                                    >
                                                        <div className="servico_container">
                                                            <div
                                                                class="icon-box circle-efect"
                                                                style={{
                                                                    marginLeft:
                                                                        "40px",
                                                                    background:
                                                                        "none",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/icone/horarios/sabado.png`}
                                                                />
                                                            </div>

                                                            <div class="contact-info-content">
                                                                <h4 className="text-center">
                                                                    Sábado
                                                                </h4>
                                                                <p className="text-center">
                                                                    07H00 -
                                                                    12H00
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Fim fim */}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {/* <Button
                                                        onClick={() =>
                                                            setShow(false)
                                                        }
                                                    >
                                                        Fechar
                                                    </Button> */}
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>
                                    {/* <div class="navbar-toggle"></div> */}

                                    <div
                                        onClick={() => {
                                            setOpen(!open);
                                        }}
                                        class="navbar-toggle  mr-3 disponivel"
                                    >
                                        <i
                                            className="fas fa-list-alt"
                                            style={{ fontSize: "2rem" }}
                                        ></i>
                                    </div>

                                </div>
                            </nav>

                            <div style={{ display: open ? "flex" : "unset" }}
                                class="responsive-menu disponivel"
                            >
                                <div
                                    class="slicknav_menu"
                                    style={{ width: "50%" }}
                                >
                                    <ul
                                        class="slicknav_nav"
                                        aria-hidden="false"
                                        role="menu"
                                    >
                                        <li class="nav-item ">
                                            <Link
                                                to="/"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                Início
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link
                                                to="/sobre"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                Sobre nós
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link
                                                to="/servico"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                Servico
                                            </Link>
                                        </li>

                                        <li class="nav-item ">
                                            <Link
                                                to="/profissional"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                Profissionais
                                            </Link>
                                        </li>

                                        <li class="nav-item ">
                                            <Link
                                                to="/galeria"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                {" "}
                                                Galerias
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link
                                                to="/contacto"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}
                                            >
                                                Contactos
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            ) : (
                <HeaderAdmin />
            )}
        </>
    );
};

export default Header;
