import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/bootstrap.min.css";
import "./assets/css/slicknav.min.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/all.css";
import "./assets/css/animate.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/mousecursor.css";
import "./assets/css/custom.css";

// <!-- Favicon Icon -->
// <link rel="shortcut icon" type="image/x-icon" href="images/favicon.png">
// <!-- Google Fonts Css-->
// <link rel="preconnect" href="https://fonts.googleapis.com/">
// <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&amp;display=swap"
//     rel="stylesheet">
// <!-- Bootstrap Css -->
// <link href="css/bootstrap.min.css" rel="stylesheet" media="screen">
// <!-- SlickNav Css -->
// <link href="css/slicknav.min.css" rel="stylesheet">
// <!-- Swiper Css -->
// <link rel="stylesheet" href="css/swiper-bundle.min.css">
// <!-- Font Awesome Icon Css-->
// <link href="css/all.css" rel="stylesheet" media="screen">
// <!-- Animated Css -->
// <link href="css/animate.css" rel="stylesheet">
// <!-- Magnific Popup Core Css File -->
// <link rel="stylesheet" href="css/magnific-popup.css">
// <!-- Mouse Cursor Css File -->
// <link rel="stylesheet" href="css/mousecursor.css">
// <!-- Main Custom Css -->
// <link href="css/custom.css" rel="stylesheet" media="screen"></link>

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
