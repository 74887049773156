import React, { useEffect, useState } from "react";
// Import Swiper React components
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import caminho from "../services/Api";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    // useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";

const Servico = () => {
    const navigate = useNavigate();
    const [servicos, setServicos] = useState([]);

    useEffect(() => {
        !localStorage.getItem("token") && navigate("/servico");

        // Servicos
        axios
            .get(caminho.servicoapi)
            .then((response) => {
                setServicos(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);

    //   var settings = {
    //     dots: true,
    //     fade: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     waitForAnimate: false,
    //     autoplaySpeed: 3000,
    //     autoplay: true,
    //   };

    return (
        <div>
            <div class="page-header" style={{ padding: "30px 0" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-header-box">
                                <h1
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    <span> Nossos </span> Serviços{" "}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-services">
                {/* Mobile */}

                <div class="container disponivel">
                    <div class="row">
                        {servicos.map((servico) => (
                            <div className="servico_padrao d-flex justify-content-center ml-3 mr-0">
                                <div className="servico_container">
                                    <div class="icon-box">
                                        <div class="img d-flex justify-content-center">
                                            <img
                                                src={`${caminho.$meustorage}/${servico.icone}`}
                                                alt=""
                                                width="40"
                                                height="40"
                                                className="pb-2"
                                            />
                                        </div>
                                    </div>
                                    <div class="service-body ">
                                        <h3 class="text-center">
                                            {servico.nome}
                                        </h3>
                                        <p class="text-center">
                                            {servico.descricao}{" "}
                                        </p>
                                    </div>
                                    <div class="read-more-btn d-flex justify-content-center">
                                        {/* <a href="service-single.html">
                                                                            Ler mais
                                                                        </a> */}

                                        <Link
                                            to={`/servicosingle/${servico.id}`}
                                        >
                                            Ler mais
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Fim Mobile */}

                <div class="container disponivel_padrao">
                    <div class="row">
                        {servicos.map((servico) => (
                            <div class="col-lg-3 col-md-6">
                                <div class="service-item wow fadeInUp">
                                    <div class="icon-box">
                                        <div class="img d-flex justify-content-center">
                                            <img
                                                // src={`${process.env.PUBLIC_URL}/assets/images/icone/servicos/clinic.png`}
                                                src={`${caminho.$meustorage}/${servico.icone}`}
                                            />
                                        </div>
                                    </div>
                                    <div class="service-body ">
                                        <h3 class="text-center">
                                            {servico.nome}{" "}
                                        </h3>
                                        <p class="text-center">
                                            {" "}
                                            {servico.descricao}{" "}
                                        </p>
                                    </div>
                                    <div class="read-more-btn d-flex justify-content-center">
                                        {/* <a href="#">Ler mais</a> */}

                                        <Link
                                            to={`/servicosingle/${servico.id}`}
                                        >
                                            Ler mais
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Servico;
