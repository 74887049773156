import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Link, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import caminho from "../services/Api";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

const ServicoSingle = () => {
    const { id } = useParams();
    const [servico, setServiceSingle] = useState("");
    const navigate = useNavigate();
    const [servicolistas, setServicos] = useState([]);

    useEffect(() => {
        // Servicos
        axios
            .get(caminho.servicosingleapi)
            .then((response) => {
                setServicos(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });

        axios
            .get(`${caminho.servicosingleshowapi}/${id}`)
            .then((response) => setServiceSingle(response.data.servico))
            .catch((error) => console.error(error));
    }, [id]);

    return (
        <div>
            <div class="page-header" style={{ padding: "30px 0" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-header-box">
                                <h1
                                    class="text-anime-style-2 disponivel"
                                    data-cursor="-opaque"
                                    style={{ fontSize: "2rem" }}
                                >
                                    <span>Serviço :</span> {servico.nome}{" "}
                                </h1>

                                <h1
                                    class="text-anime-style-2 disponivel_padrao"
                                    data-cursor="-opaque"
                                    style={{ fontSize: "2.5rem" }}
                                >
                                    <span>Serviço </span> {servico.nome}{" "}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-service-single" style={{ padding: "50px 0" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3" style={{ width: "27%" }}>
                            <div class="service-sidebar disponivel_padrao">
                                <div class="service-catagery-list wow fadeInUp">
                                    <h3>Nossos serviços</h3>

                                    <ul>
                                        {servicolistas.map((servico) => (
                                            <li>
                                                <Link
                                                    to={`/servicosingle/${servico.id}`}
                                                >
                                                    {servico.nome}{" "}
                                                </Link>
                                            </li>
                                        ))}

                                        <Link to="/servico" class="btn-default">
                                            Ver todos
                                            <span>
                                                <i
                                                    className="far fa-star"
                                                    style={{ color: "#f3b313" }}
                                                ></i>
                                            </span>
                                        </Link>
                                    </ul>
                                </div>

                                <div
                                    class="sidebar-cta-box wow fadeInUp"
                                    data-wow-delay="0.25s"
                                >
                                    <div class="icon-box">
                                        <img
                                            // src="images/icon-cta.svg"
                                            src={`${process.env.PUBLIC_URL}/assets/images/icon-cta.svg`}
                                            alt=""
                                        />
                                    </div>

                                    <div class="cta-content">
                                        {/* <h3>Precisa de ajuda ?</h3> */}
                                        <p>
                                            Agende hoje mesmo a sua consulta
                                            inicial.
                                            Entre em contacto connosco agora!
                                        </p>
                                    </div>

                                    <div class="cta-contact-btn">
                                        <Link
                                            to="/contacto"
                                            class="btn-default"
                                        >
                                            Contacta-nos
                                            <span>
                                                <i
                                                    className="far fa-star"
                                                    style={{ color: "#f3b313" }}
                                                ></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8">
                            <div class="service-single-content">
                                <div class="service-featured-image">
                                    <figure class=" image-anime">
                                        <img
                                            // src="images/service-single-img.jpg" alt=""
                                            //  src={`${caminho.$meustorage}/${servico.imagem}`}
                                            // src={`${process.env.PUBLIC_URL}/assets/images/service-single-img.jpg`}
                                            src={`${caminho.$meustorage}/${servico.imagem}`}
                                        />
                                    </figure>
                                </div>

                                <div class="service-entry">
                                    <h3 class="wow fadeInUp">
                                        {" "}
                                        {servico.nome}{" "}
                                    </h3>
                                    <p
                                        class="wow fadeInUp"
                                        data-wow-delay="0.2s"
                                    >
                                        {" "}
                                        {servico.descricao}{" "}
                                    </p>

                                    {/* <ul class="wow fadeInUp" data-wow-delay="1s">
                                <li>experienced team</li>
                                <li>comprehensive services</li>
                                <li>state-of-the-art technology</li>
                                <li>emergency dental services</li>
                            </ul> */}

                                    <div class="service-entry-image">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="service-entry-img-1">
                                                    <figure class="image-anime reveal">
                                                        <img
                                                            src="images/service-entry-img-1.jpg"
                                                            alt=""
                                                        />
                                                    </figure>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-12">
                                                <div class="service-entry-img-2">
                                                    <figure class="image-anime reveal">
                                                        <img
                                                            src="images/service-entry-img-2.jpg"
                                                            alt=""
                                                        />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicoSingle;
