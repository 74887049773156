import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const location = useLocation();

    return (
        <div>
            <footer class="main-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="about-footer">
                                <div
                                    class="footer-logo disponivel_padrao"
                                    style={{ width: "70px" }}
                                >
                                    <img
                                        // src="images/logo/logo.jpg"
                                        src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.jpg`}
                                        alt=""
                                        style={{ borderRadius: "100%" }}
                                    />
                                </div>

                                <div class="about-footer-content">
                                    <p>
                                        Na CLISPAJ, nosso compromisso é
                                        proporcionar atendimento médico de alta
                                        qualidade.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 disponivel_padrao">
                            <div class="footer-links footer-quick-links">
                                <h3>Horário de funcionamento</h3>

                                <span>Unidade 1</span>

                                <ul>
                                    <li>
                                        <a href="#">Emergência - 24/24</a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Consulta: Segunda a Sexta-feira -
                                            8H-18H
                                        </a>
                                    </li>

                                    <span>Unidade 2</span>

                                    <li>
                                        <a href="#">
                                            Segunda a Sexta-feira - 7H-19H
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">Sábado - 7H-12H</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-4 disponivel_padrao">
                            <div class="footer-links footer-social-links">
                                <h3>Redes sociais</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/clinicaispaj"
                                            target="_blank"
                                        >
                                            <i
                                                className="fab fa-facebook"
                                                style={{
                                                    color: "rgb(51, 153, 255)",
                                                }}
                                            ></i>
                                        </a>

                                        <a
                                            href="https://www.instagram.com/clinica_ispaj/"
                                            target="_blank"
                                            className="ml-4"
                                        >
                                            <i
                                                className="fab fa-instagram"
                                                style={{
                                                    color: "rgb(214, 41, 118)",
                                                }}
                                            ></i>
                                        </a>
                                    </li>

                                    {/* <li></li> */}
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-4 disponivel_padrao">
                            <div class="footer-links footer-contact-links">
                                <h3>Contacta - nos</h3>
                                <ul>
                                    <li>
                                        <a href="#">clinicaispaj@gmail.com</a>
                                    </li>
                                    <li>
                                        <a href="#">+244 950 727 259</a>
                                    </li>
                                    <li>
                                        <a href="#">+244 940 727 259</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="footer-copyright">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="footer-copyright-text">
                                    <p>
                                        Copyright © 2024 todos os direitos
                                        reservados.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
