import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header";
import axios from "axios";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import SideBarAdmin from "../layouts/SideBarAdmin";
import caminho from "../../../services/Api";

const ServicoCreate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [nome, setNome] = useState("");
    const [icone, setIcone] = useState(null);
    const [imagem, setImagem] = useState(null);
    const [descricao, setDescricao] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("icone", icone);
        formData.append("imagem", imagem);
        formData.append("descricao", descricao);

        try {
            const response = await axios.post(
                caminho.storeservicosevidorapi,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                // alert("Serviço adicionado com sucesso!")
                navigate("/admin/servicoindex");

                Swal.fire({
                    title: "Sucesso!",
                    text: "Dado inserido com sucesso!",
                    icon: "success",
                    confirmButtonText: "Sim",
                });
            } else {
                alert("Erro ao adicionar serviço.");
            }
        } catch (error) {
            console.error("Erro ao adicionar serviço", error);
            alert("Erro ao adicionar serviço.");
        }
    };

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>
            <div id="wrapper">
                <SideBarAdmin />

                <div id="main-content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <h2 style={{ textIndent: "410px" }}>
                                        <i className="far fa-plus-square"></i>{" "}
                                        Adicionar serviço
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix d-flex justify-content-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="header">
                                        {/* <h2>Adicionar serviços</h2> */}
                                    </div>
                                    <div className="body">
                                        <form
                                            id="basic-form"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Nome</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            value={nome}
                                                            onChange={(e) =>
                                                                setNome(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Imagem</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) =>
                                                                setImagem(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Icone</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) =>
                                                                setIcone(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-4">
                                                <label>Descrição</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    required
                                                    value={descricao}
                                                    onChange={(e) =>
                                                        setDescricao(
                                                            e.target.value
                                                        )
                                                    }
                                                ></textarea>
                                            </div>

                                            <br />
                                            <button
                                                type="submit"
                                                className="btn btn-primary cor_padrao"
                                            >
                                                Registar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ServicoCreate;
