import React, { useEffect, useState } from "react";
// Import Swiper React components
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    // useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import caminho from "../services/Api";
const Profissional = () => {
    const [profissionals, setProfissionals] = useState([]);

    useEffect(() => {
        // Profissionais
        axios
            .get(caminho.profissionalapi)
            .then((response) => {
                setProfissionals(response.data);
            })
            .catch((error) => {
                console.error("Houve um erro ao buscar os dados!", error);
            });
    }, []);

    return (
        <div>
            {/* <style>
        {`
          footer {
            margin-top:1000px;
          }
        `}
      </style> */}

            <div class="page-header" style={{ padding: "30px 0" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-header-box">
                                <h1
                                    class="text-anime-style-2"
                                    data-cursor="-opaque"
                                >
                                    <span>Profi</span>ssionais
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-team">
                <div class="container">
                    <div class="row">
                        {profissionals.map((profissional) => (
                            <div
                                class="col-lg-3 col-md-6 disponivel"
                                style={{
                                    width: "38%",
                                    float: "left",
                                    marginRight: "30px",
                                }}
                            >
                                <div
                                    class="team-member-item"
                                    style={{ width: "140%" }}
                                >
                                    <div class="team-image">
                                        <figure
                                            class="image-anime"
                                            style={{
                                                backgroundColor:
                                                    "hsl(0, 16%, 96%)",
                                            }}
                                        >
                                            <img
                                                // src={`${process.env.PUBLIC_URL}/assets/images/user/team-2.png`}
                                                src={`${caminho.$meustorage}/${profissional.imagem}`}
                                                alt=""
                                            />
                                        </figure>

                                        <div class="team-social-icon">
                                            {/* <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-youtube"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-x-twitter"></i>
                                                    </a>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>

                                    <div class="team-content">
                                        <h3>{profissional.nome} </h3>
                                        <p>{profissional.area} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Fim Moile */}

                <div class="container">
                    <div class="row">
                        {profissionals.map((profissional) => (
                            <div class="col-lg-3 col-md-6 disponivel_padrao">
                                <div class="team-member-item wow fadeInUp">
                                    <div class="team-image">
                                        <figure
                                            class="image-anime"
                                            style={{
                                                backgroundColor:
                                                    "hsl(0, 16%, 96%)",
                                            }}
                                        >
                                            <img
                                                // src="images/user/team-2.png"
                                                // src={`${process.env.PUBLIC_URL}/assets/images/user/team-2.png`}
                                                src={`${caminho.$meustorage}/${profissional.imagem}`}
                                                alt=""
                                            />
                                        </figure>

                                        <div class="team-social-icon">

                                            {/* <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-youtube"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#"
                                                        class="social-icon"
                                                    >
                                                        <i class="fa-brands fa-x-twitter"></i>
                                                    </a>
                                                </li>
                                            </ul> */}

                                        </div>
                                    </div>

                                    <div class="team-content">
                                        <h3> {profissional.nome} </h3>
                                        <p> {profissional.area} </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profissional;
