import axios from "axios";

// src/api/apiConfig.js
const API_BASE_URL = "http://localhost:8000/api/auth";
// const ARMAZENA_FILE = "http://localhost:8000/storage";
const ARMAZENA_FILE = "http://localhost:8000/storage";

// const API_BASE_URL = "http://10.10.10.36:8000/api/auth";
// const ARMAZENA_FILE = "http://10.10.10.36:8000/storage";

const caminho = {
    // $patharmazenamento: `${ARMAZENA_FILE}`,
    $meustorage: `${ARMAZENA_FILE}`,

    // Cliente
    bannerapi: `${API_BASE_URL}/banner`,
    servicoapi: `${API_BASE_URL}/servico`,
    profissionalapi: `${API_BASE_URL}/profissional`,
    contactoapi: `${API_BASE_URL}/contacto/store`,
    galeriaapi: `${API_BASE_URL}/galeria`,
    servicoapi: `${API_BASE_URL}/servico`,

    //ServiceSingle
    servicosingleapi: `${API_BASE_URL}/servicosinglelist`,
    servicosingleshowapi: `${API_BASE_URL}/servicosingle`,

    // API do  Servidor----------------------------------------------------

    // Servico
    servicosevidorapi: `${API_BASE_URL}/servicoservidor`,
    deleteservicosevidorapi: `${API_BASE_URL}/servico`,
    storeservicosevidorapi: `${API_BASE_URL}/servico/store`,
    editservicosevidorapi: `${API_BASE_URL}/servico/servicoedit`,
    updateservicosevidorapi: `${API_BASE_URL}/servico/update`,

    // Profissionais
    profissionalsevidorapi: `${API_BASE_URL}/profiservidor`,
    deleteprofissionalsevidorapi: `${API_BASE_URL}/profissional`,
    storeprofissionalsevidorapi: `${API_BASE_URL}/profissional/store`,
    editprofissionalsevidorapi: `${API_BASE_URL}/profissional/profissionaledit`,
    updateprofissionalsevidorapi: `${API_BASE_URL}/profissional/update`,

    //Galeria
    galeriasevidorapi: `${API_BASE_URL}/galeriaservidor`,
    deletegaleriasevidorapi: `${API_BASE_URL}/galeria`,
    storegaleriasevidorapi: `${API_BASE_URL}/galeria/store`,
    editgaleriasevidorapi: `${API_BASE_URL}/galeria/galeriaedit`,
    updategaleriasevidorapi: `${API_BASE_URL}/galeria/update`,

    //banner
    bannersevidorapi: `${API_BASE_URL}/bannerservidor`,
    deletebannersevidorapi: `${API_BASE_URL}/banner`,
    storebannersevidorapi: `${API_BASE_URL}/banner/store`,
    editbannersevidorapi: `${API_BASE_URL}/banner/banneredit`,
    updatebannersevidorapi: `${API_BASE_URL}/banner/update`,

    // contacto
    contactosevidorapi: `${API_BASE_URL}/contacto`,
    deletecontactosevidorapi: `${API_BASE_URL}/contacto`,

    // posts: `${API_BASE_URL}/posts`,
    // Adicione outros endpoints aqui
};

export default caminho;
