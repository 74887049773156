import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom";
import Authservice from "../../../services/Authservice";

const HeaderAdmin = () => {
    return (
        <nav class="navbar navbar-fixed-top">
            <div class="container-fluid">
                <div class="navbar-brand">
                    <Link to="/admin">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.jpg`}
                            width="40"
                            alt="Lucid Logo"
                            class="img-responsive logo mr-3"
                            style={{
                                width: "50px",
                                height: "50px",
                            }}
                        />
                    </Link>
                </div>

                <div class="navbar-right">
                    <div id="navbar-menu">
                        <ul class="nav navbar-nav">
                            <li>
                                <a
                                    onClick={() => {
                                        Authservice.logout();
                                    }}
                                    class="icon-menu"
                                    style={{ cursor: "pointer" }}
                                >
                                    <i class="icon-login"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default HeaderAdmin;
