import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header";
import axios from "axios";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import SideBarAdmin from "../layouts/SideBarAdmin";
import caminho from "../../../services/Api";

const BannerCreate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [imagem, setImagem] = useState(null);
    const [tituloum, setTituloum] = useState("");
    const [titulodois, setTitulodois] = useState("");
    const [titulotres, setTitulotres] = useState("");
    const [iconeum, setIconeum] = useState("");
    const [iconedois, setIconedois] = useState("");
    const [iconetres, setIconetres] = useState("");
    const [descricao, setDescricao] = useState("");

    // const [icone, setIcone] = useState("");
    // const [descricao, setDescricao] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("imagem", imagem);
        formData.append("tituloum", tituloum);
        formData.append("titulodois", titulodois);
        formData.append("titulotres", titulotres);
        formData.append("iconeum", iconeum);
        formData.append("iconedois", iconedois);
        formData.append("iconetres", iconetres);
        formData.append("descricao", descricao);

        try {
            const response = await axios.post(
                caminho.storebannersevidorapi,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                // alert("Serviço adicionado com sucesso!")
                navigate("/admin/bannerindex");
                Swal.fire({
                    title: "Sucesso!",
                    text: "Dado inserido com sucesso!",
                    icon: "success",
                    confirmButtonText: "Sim",
                });
            } else {
                alert("Erro ao adicionar serviço.");
            }
        } catch (error) {
            console.error("Erro ao adicionar serviço", error);
            alert("Erro ao adicionar serviço.");
        }
    };

    return (
        <div>
            <style>
                {`
          footer {
            display:none;
          }

          h1, h2, h3, h4, h5, h6 {
            line-height:0;
          }

        `}
            </style>
            <div id="wrapper">
                <SideBarAdmin />

                <div id="main-content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <h2 style={{ textIndent: "410px" }}>
                                        <i className="far fa-plus-square"></i>{" "}
                                        Registar Banner
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix d-flex justify-content-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="header">
                                        {/* <h2>Adicionar serviços</h2> */}
                                    </div>
                                    <div className="body">
                                        <form
                                            id="basic-form"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Título um</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            value={tituloum}
                                                            onChange={(e) =>
                                                                setTituloum(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Título dois
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            value={titulodois}
                                                            onChange={(e) =>
                                                                setTitulodois(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Título tres
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            value={titulotres}
                                                            onChange={(e) =>
                                                                setTitulotres(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Imagem</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) =>
                                                                setImagem(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>Icone um</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                setIconeum(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>
                                                            Icone dois
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                setIconedois(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    {/* Upload imagem */}
                                                    <div className="form-group ">
                                                        <label>
                                                            Icone três
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                setIconetres(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-4">
                                                <label>Descrição</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    required
                                                    value={descricao}
                                                    onChange={(e) =>
                                                        setDescricao(
                                                            e.target.value
                                                        )
                                                    }
                                                ></textarea>
                                            </div>

                                            <br />
                                            <button
                                                type="submit"
                                                className="btn btn-primary cor_padrao"
                                            >
                                                Registar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerCreate;
