import React, { useEffect, useState } from "react";
import AuthService from "../services/Authservice";
// import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    //   const history = useHistory();

    const handleLogin = async () => {
        try {
            const response = await AuthService.login(email, password);
            localStorage.setItem("token", response.authorisation.token);
            localStorage.setItem("email", response.user.email);

            // Handle successful login, e.g., redirect
            console.log("Login successful");
            navigate("/admin");
            //   history.push("/");

            // eslint-disable-next-line react/jsx-no-undef
        } catch (error) {
            console.error("Login error:", error);
            setError("Login falhou. Verifique suas credenciais.");
        }
    };
    useEffect(() => {
        localStorage.getItem("token") && navigate("/admin");
    }, []);

    return (
        <div>
            <style>
                {`

        footer {
          display:none;
        }
              
          header {
            display:none;
          }
        `}
            </style>

            <div className="container" style={{ marginTop: "250px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-7">
                        <div className="card login-page shadow mt-4 rounded border-0 mb-4">
                            <div className="card-body">
                                {/* <h4 className="text-center">Logar</h4> */}

                                <Link class="navbar-brand  ml-3 d-flex justify-content-center">
                                    <img
                                        // src=""
                                        src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.jpg`}
                                        width="50"
                                        height="50"
                                        alt="Logo"
                                    />
                                    {/* <span class="m-2 ml-2">CLISPAJ</span> */}
                                </Link>

                                {error && (
                                    <p className="text-center text-danger">
                                        {error}
                                    </p>
                                )}
                                {/* <form className="login-form mt-4"> */}

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Email{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Senha{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Senha"
                                                value={password}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <div className="d-grid">
                                            <button
                                                className="btn btn-primary p-2"
                                                onClick={handleLogin}
                                                style={{
                                                    backgroundColor: "#f3b313",
                                                    border: "1px solid #f3b313",
                                                    width: "130%",
                                                }}
                                            >
                                                Entrar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        {/* <p className="mb-0 mt-3">
                    <small className="text-dark me-2">Já tem uma conta?</small>{" "}
                    <a href="register.html" className="text-dark fw-bold">
                      Registre-se
                    </a>
                  </p> */}
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
